// extracted by mini-css-extract-plugin
export var root = "header-module--root--1F4B0";
export var wrapper = "header-module--wrapper--1psFb";
export var menuIcon = "header-module--menuIcon--2STbk";
export var branding = "header-module--branding--S4Gb1";
export var active = "header-module--active--3dC7Y";
export var toggleNavButton = "header-module--toggleNavButton--3E43G";
export var nav = "header-module--nav--3sVWi";
export var fadein = "header-module--fadein--3PlDg";
export var fadeIn = "header-module--fadeIn--3VPST";
export var social = "header-module--social--3GeSP";
export var showNav = "header-module--showNav--1p8i-";
export var closeNav = "header-module--closeNav--1aiS0";